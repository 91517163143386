import { Container } from '../../shared/components/Container';
import { Highlight, ProjectBuy, ProjectImgBox, ProjectList, ProjectListItem, ProjectName, ProjectSubHeading, ProjectText, ProjectTextContent, ProjectWrap } from '../../shared/components/ProjectElements';

export const Sweeping = () => {
	return (
		<Container>
			<ProjectWrap>
				<ProjectName>Sweeping Tool For Cardano NFTs</ProjectName>
				<ProjectTextContent>
					<ProjectSubHeading>Project Summary</ProjectSubHeading>
					<ProjectList>
						<ProjectListItem>
							<Highlight>Idea:</Highlight>Service created to buy a large quantity of Cardano NFTs on Cardano marketplaces with one click. Sweeping Tool will be a valuable utility for holders who wish to buy many NFTs or those who want to be first to buy NFTs at a specific event, for example, right after the minting. Sweeping Tool will have the following functionality: auto purchasing a large number of NFTs with one click, scheduled buying of NFTs once floor reaches a certain level (fully auto mode - no actions required from the customer once Tool is set), scheduled buying of NFT once floor at specific trait reaches a certain level. The first marketplace to work with will be jpg.store, but in the future, we will expand the list of marketplaces.

						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Implementation:</Highlight> Software product in the form of a Google Chrome extension
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>The essence of the software product:</Highlight> A service that helps the user to buy a large number of NFTs at once on secondary market with useful filters and tools.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Cost of services:</Highlight> 7000 С4/month
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Required investment:</Highlight> 7,000,000 C4
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>APR:</Highlight> 130% (average APR based on exchange rate 1 ADA = 354 C4, if exchange rate will change amount of profit in $C4 might change)
						</ProjectListItem>

						<ProjectListItem>
							<Highlight>Market capacity: </Highlight> about 30,000 potential customers in the crypto community.
						</ProjectListItem>
					</ProjectList>
				</ProjectTextContent>
				<ProjectListItem>
					<Highlight>Install time:</Highlight> up to 2 weeks after full amount of $c4 required is collected.
				</ProjectListItem>
				<ProjectListItem>
					<Highlight>Project demo:</Highlight> coming soon
				</ProjectListItem>
				<ProjectListItem>
					<Highlight>To invest $C4 into the project, please visit Swmaplands Dashboard, menu tab $C4 Investments. Codewaare only delivers the product, all investments happen through Swamplands Dashboard.</Highlight>
				</ProjectListItem>
				<ProjectBuy onClick={() => window.location.href='https://swamplands.cardanocrocsclub.com/'} />
			</ProjectWrap>
		</Container>
	);
};
