import { PropsWithChildren } from 'react';
import './styles.scss';

interface ImgBoxProps {
	className: string;
	img: string;
	height: number;
	width: number;
}

interface ProjectBuyProps {
	onClick: () => void;
}

export const ProjectWrap = ({ children }: PropsWithChildren) => {
	return <div className="project">{children}</div>;
};

export const ProjectList = ({ children }: PropsWithChildren) => {
	return <ul className="project__list">{children}</ul>;
};

export const ProjectName = ({ children }: PropsWithChildren) => {
	return <h1 className="project__name">{children}</h1>;
};

export const ProjectListItem = ({ children }: PropsWithChildren) => {
	return <li className="project__list-item">{children}</li>;
};

export const Highlight = ({ children }: PropsWithChildren) => {
	return <span className="highlight">{children}</span>;
};

export const ProjectTextContent = ({ children }: PropsWithChildren) => {
	return <div className="project__text-content">{children}</div>;
};

export const ProjectSubHeading = ({ children }: PropsWithChildren) => {
	return <h2 className="project__subheading">{children}</h2>;
};

export const ProjectText = ({ children }: PropsWithChildren) => {
	return <p className="project__text">{children}</p>;
};

export const ProjectImgBox: React.FC<ImgBoxProps> = (props) => {
	return (
		<div className="project__img-box">
			<img src={require(`${props.img}`)} alt="table" width={props.width} height={props.height} className={`project__img   ${props.className}`} />
		</div>
	);
};

export const ProjectBuy: React.FC<ProjectBuyProps> = (props) => {
	return (
		<div className="project__btn-box">
			<button onClick={()=>{window.location.href="https://swamplands.cardanocrocsclub.com"}} className="project__btn">
				Swamplands Dashboard
			</button>
		</div>
	);
};
