import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Footer } from '../fuatures/Footer';
import { Header } from '../fuatures/Header';
import { Main } from '../pages/Main';
import { Project } from '../pages/Project';
import { SecureVPN } from '../pages/SecureVPN';
import topLeaf from './img/top-leaf.svg';
import middleLeaf from './img/middle-leaf.svg';
import bottomLeaf from './img/bottom-leaf.svg';
import './index.scss';

function App() {
	return (
		<BrowserRouter>
			<div className="wrap">
				<Header />
				<Routes>
					<Route path="/" element={<Main />} />
					<Route path="/project/*" element={<Project />} />

					<Route path="*" element={<Main />} />
				</Routes>
				<Footer />
				<img src={topLeaf} alt="leaf" width={401} height={531} className="decor decor--top" />
				<img src={middleLeaf} alt="lead" width={325} height={525} className="decor decor--middle" />
				<img src={bottomLeaf} alt="lead" width={337} height={561} className="decor decor--bottom" />
			</div>
		</BrowserRouter>
	);
}

export default App;
