import { Container } from '../../shared/components/Container';
import { Highlight, ProjectBuy, ProjectImgBox, ProjectList, ProjectListItem, ProjectName, ProjectSubHeading, ProjectText, ProjectTextContent, ProjectWrap } from '../../shared/components/ProjectElements';

export const Hosting = () => {
	return (
		<Container>
			<ProjectWrap>
				<ProjectName>Blockchain file Hosting</ProjectName>
				<ProjectTextContent>
					<ProjectSubHeading>Project Summary</ProjectSubHeading>
					<ProjectList>
						<ProjectListItem>
							<Highlight>Idea:</Highlight> Web application, where people can permanently and anonymously host their files of ANY size (ex: images, applications etc) on the Cardano blockchain, fully annonymosly without monthly payments for the storage used. The only payments made are one-time payment for the trasnaction fees, and fairly small monthly fee to the service to manage files.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Implementation:</Highlight> Software product in the form of a website.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>The essence of the software product:</Highlight>Application that breaks files into small chunks to fit Cardano blockchain transaction size when files is uploaded, and forms file from small chunks when file is downloaded.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Price:</Highlight>  10000 $C4 monthly
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Required investment:</Highlight> 30,000,000 C4
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Expected yearly revenue:</Highlight> 180%
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Payback period of the project:</Highlight> from 8 months. Project works in real life market conditions and time required for payback in $C4 might change in case of significant change of $C4 price.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Investor benefit:</Highlight>70% from the project income during all of the existence of the project.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Market capacity:</Highlight> millions of crypto users who would love to share information without overpaying for the storage and additional censorship.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Install time:</Highlight> up to 4 weeks after full amount of $c4 required is collected.
						</ProjectListItem>

					</ProjectList>
				</ProjectTextContent>

				<ProjectTextContent>
					<b>To invest $C4 into the project, please visit Swmaplands Dashboard, menu tab $C4 Investments. Codeware only delivers the product, all investments happen through Swamplands Dashboard.</b>
				</ProjectTextContent>
				<ProjectBuy onClick={() => alert('Coming soon')} />
			</ProjectWrap>
		</Container>
	);
};