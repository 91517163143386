import './styles.scss';
import logo from './img/logo.svg';
import { Container } from '../../shared/components/Container';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const Header = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<header className="header">
			<Container className="header__container">
				<div className="header__inner">
					<NavLink to={'/'} className="logo header__logo">
						<img src={logo} alt="logo" width="140" height="67" className="logo__img" />
					</NavLink>
					<nav className={`nav header__nav ${isOpen ? 'nav--active' : ''}`}>
						<ul className="menu-list nav__menu-list">
						
							<li className="menu-list__item">
								<NavLink to={'/'} className="menu-list__link">
									Marketplace
								</NavLink>
							</li>

						</ul>
					</nav>
					<button onClick={() => setIsOpen((state) => !state)} className={`burger-btn header__burger-btn ${isOpen ? 'burger-btn--active' : ''}`} type="button">
						<span className="burger-btn__inner">
							<span className="burger-btn__item"></span>
							<span className="burger-btn__item"></span>
							<span className="burger-btn__item"></span>
						</span>
					</button>
				</div>
			</Container>
		</header>
	);
};
