import { Container } from '../../shared/components/Container';
import { ProjectItem } from '../ProjectItem';
import './styles.scss';

export const Projects = () => {
	return (
		<Container>
			<div className="projects">
				<h2 className="projects__header">Projects</h2>
				<div className="projects__list">
					<ProjectItem link="/project/secure-vpn" img={'./img/vpn.png'} name={'Secure VPN'} platform={'Mobile'} type={'Mobile App'} APR={'150%'} setUpfee={'10 000 000 C4'} collected={'10000000'} investors={'4'} total={'10000000'} daysLeft={'4'} />
					<ProjectItem link="/project/nft-generator" img={'./img/nft.png'} name={'NFT Image Generator'} platform={'Web'} type={'dApp'} APR={'110%'} setUpfee={'5 000 000 C4'} collected={'5000000'} investors={'1'} total={'5000000'} daysLeft={'1'} />
					<ProjectItem link="/project/sweeping-tool" img={'./img/sweep.png'} name={'Sweeping Tool for Cardano NFT'} platform={'Web'} type={'dApp'} APR={'130%'} setUpfee={'7 000 000 C4'} collected={'7000000'} investors={'1'} total={'7000000'} daysLeft={'1'} />

					<ProjectItem link="/project/hosting" img={'./img/hosting.png'} name={'Blockchain File Hosting'} platform={'Web'} type={'dApp'} APR={'180%'} setUpfee={'30 000 000 C4'} collected={'30000000'} investors={'1'} total={'30000000'} daysLeft={'1'} />
					<ProjectItem link="/project/marketplace" img={'./img/marketplace.png'} name={'Multichain NFT Marketplace'} platform={'Web'} type={'dApp'} APR={'160% with 300% increase potential'} setUpfee={'80 000 000 C4'} collected={'15000000'} investors={'1'} total={'80000000'} daysLeft={'1'} />

					<ProjectItem link="/project/sms-service" img={'./img/sms.jpg'} name={'Blockchain SMS Service'} platform={'Web and Mobile App'} type={'dApp'} APR={'up to 210%'} setUpfee={'120 000 000 C4'} collected={'0'} investors={'1'} total={'120000000'} daysLeft={'1'} />

				</div>
			</div>
		</Container>
	);
};
