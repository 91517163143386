import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.scss';
import { Navigation } from 'swiper';
import { Container } from '../../shared/components/Container';
import { Highlight, ProjectBuy, ProjectImgBox, ProjectList, ProjectListItem, ProjectName, ProjectSubHeading, ProjectText, ProjectTextContent, ProjectWrap } from '../../shared/components/ProjectElements';

import arrowLeft from './img/left-arrow.svg';
import arrowRight from './img/right-arrow.svg';

export const SecureVPN = () => {
	return (
		<Container>
			<ProjectWrap>
				<ProjectName>Secure VPN</ProjectName>
				<ProjectTextContent>
					<Swiper
						navigation={{
							nextEl: '.project__swiper-arrow--right',
							prevEl: '.project__swiper-arrow--left',
						}}
						breakpoints={{
							320: {
								width: 266,
								slidesPerView: 1,
							},

							375: {
								width: 321,
								slidesPerView: 1,
							},
							425: {
								width: 371,
								slidesPerView: 1,
							},

							768: {
								width: 1082,
								slidesPerView: 5,
							},
						}}
						spaceBetween={35}
						modules={[Navigation]}
						className="project__swiper">
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-1.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-2.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-3.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-4.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-5.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-6.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-7.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
						<SwiperSlide className="project__swiper-slide">
							<img src={require('./img/slide-8.png')} width={194} height={355} className="project__swiper-slide-img" alt="sldie" />
						</SwiperSlide>
					</Swiper>
					<div className="project__swiper-arrow project__swiper-arrow--left">
						<img src={arrowLeft} alt="arrow" width={24} height={24} />
					</div>
					<div className="project__swiper-arrow project__swiper-arrow--right">
						<img src={arrowRight} alt="arrow" width={24} height={24} />
					</div>
				</ProjectTextContent>
				<ProjectTextContent>
					<ProjectSubHeading>Project Summary</ProjectSubHeading>
					<ProjectList>
						<ProjectListItem>
							<Highlight>Idea:</Highlight> creation of the VPN service for companies and individuals, primarly in the Cardano crypto space to provide a secure and fast way to surf the internet for crypto users and unlock all crypto opportunities for members from all countries.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Implementation:</Highlight> Software product in the form of a mobile application for Android and iOS
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>The essence of the software product:</Highlight> A service that helps the user hide from surveillance from third parties.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Subscription cost for service providers:</Highlight> 5 devices per 1 account, 2000 servers for 50 countries, 30 days money back guarantee, 5000 C4 per month. Price might change if price of $C4 changes.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Required investment:</Highlight> 10,000,000 C4
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Payback period of the project:</Highlight> from 8 months. Project works in real life market conditions and time required for payback in $C4 might change in case of significant change of $C4 price.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Market capacity:</Highlight> about 20,000 potential subscribers in the Cardano NFT community.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Install time:</Highlight> up to 1 week after full amount of $c4 required is collected.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Project demo:</Highlight> coming soon
						</ProjectListItem>
					</ProjectList>
				</ProjectTextContent>




				<ProjectTextContent>
					<ProjectImgBox className={'securevpn--table-1'} img={'./img/table-2.png'} width={650} height={213} />
				</ProjectTextContent>
				<ProjectBuy onClick={() => alert('Coming soon')} />
			</ProjectWrap>
		</Container>
	);
};
