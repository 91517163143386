import { Container } from '../../shared/components/Container';
import { Highlight, ProjectBuy, ProjectImgBox, ProjectList, ProjectListItem, ProjectName, ProjectSubHeading, ProjectText, ProjectTextContent, ProjectWrap } from '../../shared/components/ProjectElements';

export const Marketplace = () => {
	return (
		<Container>
			<ProjectWrap>
				<ProjectName>Multichain NFT Marketplace of a new type</ProjectName>
				<ProjectTextContent>
					<ProjectSubHeading>Project Summary</ProjectSubHeading>
					<ProjectList>
						<ProjectListItem>
							<Highlight>Idea:</Highlight><p >
							<span ><span  >Multi-chain NFT Marketplace of a new type</span></span>
						</p>
							<p >
								&nbsp;
							</p>
							<p >
								<span ><span>A web + mobile application that will allow minting and trading NFTs from multiple blockchains.
									&nbsp;</span></span>
							</p>
							<p >
								<span ><span >On the marketplace, the following  trading directions are planned:&nbsp;</span></span>
							</p>
							<br/>
							<ol>
								<li >
									<span ><span >1. NFT (any blockchain) to NFT (any blockchain). NFTs from any blockchain can be traded against each other.</span></span>
								</li>
								<li >
									<span ><span  data-preserver-spaces="true">2. NFT (any blockchain) to any cryptocurrency or fiat. NFTs from any blockchain can be bought/sold for any cryptocurrency or fiat.</span></span>
								</li>
								<li >
									<span ><span  data-preserver-spaces="true">3. Services/products to NFT/crypto. Anyone can list their service or product for a certain NFT-project, cryptocurrency, or token.
.&nbsp;</span></span>
								</li>
							</ol>
							<br/>
							<p >
								<span ><span data-preserver-spaces="true">Everything on the marketplace is managed by smart contracts with maximum automation. All fees are paid in $C4.&nbsp;</span></span>
							</p>
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Implementation:</Highlight> A software product in the form of a website and mobile application.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>The essence of the software product:</Highlight>a web + mobile application that will allow minting and trading NFTs from multiple blockchains, which will include different trading directions that the space has not seen before.


						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Price:</Highlight>  1-5% fees in $C4. Fees depend on the amount and direction of the exchange.

						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Required investment:</Highlight> 80,000,000 $C4
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Expected yearly revenue:</Highlight> 160%, with a rapid increase in the middle and long term. Depending on market conditions, we expect an increase of up to 300% in the first 18-20 months due to the hight demand in such a marketplace.


						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Payback period of the project:</Highlight>a minimum of 9 months. The project works in real life market conditions and the time required for the payback in $C4 might change in case of a significant change of the $C4 price.

						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Investor benefit:</Highlight>70% from the project income during the existence of the project.

						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Market capacity:</Highlight> Millions of crypto and NFT users from all blockchains.

						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Install time:</Highlight> up to 6 weeks after the full amount of $c4 required has been collected.
						</ProjectListItem>

					</ProjectList>
				</ProjectTextContent>

				<ProjectTextContent>
					<b>To invest $C4 into the project, please visit the Swamplands dashboard, menu tab ‘$C4 Investments’. Codeware only delivers the product, all investments happen through the Swamplands dashboard.</b>
				</ProjectTextContent>
				<ProjectBuy onClick={() => alert('Coming soon')} />
			</ProjectWrap>
		</Container>
	);
};