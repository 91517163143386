import { Link } from 'react-router-dom';
import { Progress } from '../Progress';
import arrow from './img/arrow.svg';
import './styles.scss';
import { ProjectItemProps } from './types';

export const ProjectItem: React.FC<ProjectItemProps> = (props) => {
	return (
		<div className="project-item">
			<img src={require(`${props.img}`)} alt="project-img" className="project-item__img" width={322} height={240} />
			<div className="project-item__description">
				<h3 className="project-item__name">{props.name}</h3>
				<ul className="project-item__deteils-list">
					<li className="project-item__details-item">Platform: {props.platform}</li>
					<li className="project-item__details-item">Project type: {props.type}</li>
					<li className="project-item__details-item">Expected yearly revenue: {props.APR}</li>
					<li className="project-item__details-item">Set up fee: {props.setUpfee} </li>
				</ul>
			</div>
			<Progress collected={props.collected} investors={props.investors} total={props.total} daysLeft={props.daysLeft} />
			<div className="project-item__btn-box">
				<Link to={props.link} className="project-item__btn">
					<span className="project-item__btn-title">More Details</span> <img className="project-item__btn-icon" src={arrow} alt="arrow" width={40} height={40} />
				</Link>
			</div>
		</div>
	);
};
