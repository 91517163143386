import { useEffect, useRef, useState } from 'react';
import './styles.scss';

interface ProgressProps {
	collected: string;
	investors: string;
	total: string;
	daysLeft: string;
}

export const Progress: React.FC<ProgressProps> = (props) => {
	const [percents, setPercents] = useState(0);
	const barRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		setPercents(Math.floor(+props.collected / (+props.total / 100)));
		console.log(Number(props.collected), +props.total);
		console.log(Math.floor(+props.collected / (+props.total / 100)));
		if (barRef.current) {
			barRef.current.style.width = Math.floor(percents) + '%';
		}
	});
	return (
		<div className="progress">
			<div className="progress__heading">
				<div className="progress__collected">
					{Number(props.collected).toLocaleString('ru-RU')}
					<span className="progress__currency">$C4</span>
				</div>
				
			</div>
			<div className="progress__bar">
				<div ref={barRef} className="progress__bar-line"></div>
			</div>
			<div className="progress__footer">
				<div className="progress__percent">
					{percents}% of {Number(props.total).toLocaleString('ru-RU')}$C4
				</div>

			</div>
		</div>
	);
};
