import { Projects } from '../../fuatures/Projects';
import './style.scss';

export const Main = () => {
	return (
		<div className="main">
			<Projects />
		</div>
	);
};
