import { Route, Routes } from 'react-router-dom';
import { Pagination } from '../../fuatures/Pagination';
import { NFTGenerator } from '../NFTGenerator';
import { SecureVPN } from '../SecureVPN';
import './styles.scss';
import leaf from './img/bottom-left.svg';

import { Hosting } from '../Hosting';
import { Sweeping } from '../Sweeping';
import { Marketplace } from '../Marketplace';
import { Smsservice } from '../Smsservice';

export const Project = () => {
	return (
		<div className="project-wrap">
			<Routes>
				<Route path="marketplace" element={<Marketplace />} />
				<Route path="secure-vpn" element={<SecureVPN />} />
				<Route path="nft-generator" element={<NFTGenerator />} />
			
				<Route path="hosting" element={<Hosting />} />
				<Route path="sweeping-tool" element={<Sweeping />} />
				<Route path="sms-service" element={<Smsservice />} />
			</Routes>
		
			<img src={leaf} alt="te" width={539} height={374} className="decor__bottom" />
		</div>
	);
};
