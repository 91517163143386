import { Container } from '../../shared/components/Container';
import { Highlight, ProjectBuy, ProjectImgBox, ProjectList, ProjectListItem, ProjectName, ProjectSubHeading, ProjectText, ProjectTextContent, ProjectWrap } from '../../shared/components/ProjectElements';

export const Smsservice = () => {
	return (
		<Container>
			<ProjectWrap>
				<ProjectName>Decentralized SMS service built on blockchain technology</ProjectName>

				<ProjectTextContent>
					<ProjectSubHeading>I. Executive Summary</ProjectSubHeading>
					<ProjectText>
						Our decentralized SMS service is a revolutionary platform built on blockchain technology that aims to disrupt the traditional SMS industry by providing a reliable, secure, and cost-effective way to send and receive text messages. Our platform leverages the decentralized nature of blockchain technology to ensure privacy and security while offering an unparalleled user experience.
						<br/>
						<b>Our platform will offer a number of features, including:</b>
						<br/>
						Our decentralized SMS service is built on the Cardano blockchain and uses smart contracts to facilitate messaging transactions. Users will be able to send and receive text messages using our mobile app or web interface. The app will be available for both iOS and Android devices.

						Our platform will also offer an API that can be integrated with third-party applications, enabling businesses to send SMS messages to their customers directly from their own applications. This feature will be particularly useful for businesses that rely heavily on SMS for customer engagement, such as banks, airlines, and retailers.

						Our revenue model will be based on a pay-per-message system, where users will be charged a fee for each message sent or received. We will also offer subscription plans for heavy users, which will provide discounted rates for high volume messaging.

					</ProjectText>
					<ProjectSubHeading>II. Business Description</ProjectSubHeading>
					<ProjectText>
						Our decentralized SMS service is a revolutionary platform built on blockchain technology that aims to disrupt the traditional SMS industry by providing a reliable, secure, and cost-effective way to send and receive text messages. Our platform leverages the decentralized nature of blockchain technology to ensure privacy and security while offering an unparalleled user experience.
						<br/>
						<b>Our platform will offer a number of features, including:</b>
						<br/>
						Peer-to-peer messaging,<br/>
						SMS broadcasting,<br/>
						Group messaging,<br/>
						SMS API integration,<br/>
						We anticipate significant growth in the SMS market and believe that our platform will capture a significant share of the market due to its unique features and benefits.
					</ProjectText>
					<ProjectSubHeading>III. Marketing Strategy</ProjectSubHeading>
					<ProjectText>
						Our decentralized SMS service is a revolutionary platform built on blockchain technology that aims to disrupt the traditional SMS industry by providing a reliable, secure, and cost-effective way to send and receive text messages. Our platform leverages the decentralized nature of blockchain technology to ensure privacy and security while offering an unparalleled user experience.
						<br/>
						Our target market will be individuals and businesses that rely on SMS messaging for communication. We will focus on marketing our platform to the following segments:
						<br/>
						Individuals with limited SMS plans or international SMS needs<br/>
						Small businesses and startups with limited marketing budgets<br/>
						Enterprises looking to streamline their messaging processes<br/>
						We will leverage social media, content marketing, and targeted advertising to reach our target audience. We will also engage in partnerships with telecom companies and SMS aggregators to expand our reach.
					</ProjectText>
					<ProjectSubHeading>IV. Operational Plan</ProjectSubHeading>
					<ProjectText>

						Our team will consist of experienced blockchain developers, marketers, and customer service representatives. We will adopt an agile development process to ensure continuous improvement of our platform.

						Our technology stack will consist of Ethereum blockchain, Solidity smart contracts, IPFS for decentralized storage, and React Native for mobile app development.


					</ProjectText>

					<ProjectSubHeading>V. Financial Plan</ProjectSubHeading>
					<ProjectText>
						Our revenue projections are based on an average fee of $USDC4 0.01 per message or other special payment packages, or equivalent in $C4, since we plan to use C4 and USDC4 as payment methods. We expect to generate monthly revenue of $USDC4 200,000 in the first year, growing to $USDC4 500,000 in the third year. Our initial funding requirements are 120000000 $C4, which will cover the development of our platform, marketing, and operational costs for the first year.
					</ProjectText>
					<ProjectSubHeading>VI. Risks and Challenges</ProjectSubHeading>
					<ProjectText>
						The main risks and challenges associated with our business include:
						<br/>
						Regulatory risks: The regulatory landscape around blockchain technology is still uncertain, and we may face challenges in complying with regulations in different jurisdictions.<br/>
						Competition: The SMS market is highly competitive, and we may face challenges in capturing market share from established players.<br/>
						Technology risks: The success of our platform depends on the reliability and scalability of the underlying blockchain technology.<br/>
						We plan to mitigate these risks by engaging with regulatory bodies, offering a unique value proposition to differentiate ourselves from competitors, and adopting best practices for blockchain development.

					</ProjectText>

					<ProjectSubHeading>VII. Conclusion</ProjectSubHeading>
					<ProjectText>

						<br/>
						Our decentralized SMS service offers a revolutionary platform that leverages blockchain technology to provide a reliable, secure, and cost-effective way to send and receive text messages. We believe that our platform will disrupt the traditional SMS industry and capture a significant share of the market.

					</ProjectText>
				</ProjectTextContent>

				<ProjectTextContent>
					<ProjectSubHeading>What does the investor get for 120,000,000 С4:</ProjectSubHeading>
					<ProjectText>
						70% profit from the project's revenue
					</ProjectText>
				</ProjectTextContent>



				<ProjectListItem>
					<Highlight>To invest $C4 into the project, please visit Swmaplands Dashboard, menu tab $C4 Investments. Codeware only delivers the product, all investments happen through Swamplands Dashboard.</Highlight>
				</ProjectListItem>
				<ProjectBuy onClick={() => window.location.href='https://swamplands.cardanocrocsclub.com/'} />

			</ProjectWrap>
		</Container>
	);
};
