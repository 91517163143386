import { Container } from '../../shared/components/Container';
import { Highlight, ProjectBuy, ProjectImgBox, ProjectList, ProjectListItem, ProjectName, ProjectSubHeading, ProjectText, ProjectTextContent, ProjectWrap } from '../../shared/components/ProjectElements';

export const NFTGenerator = () => {
	return (
		<Container>
			<ProjectWrap>
				<ProjectName>NFT Image Generator</ProjectName>
				<ProjectTextContent>
					<ProjectSubHeading>Project Summary</ProjectSubHeading>
					<ProjectList>
						<ProjectListItem>
							<Highlight>Idea:</Highlight> Web application, with help of which, NFT founders will be able easily without any code knowledge generate PNG images and metadata based on PSD layers, rarity charts and other conditions.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Implementation:</Highlight> Software product in the form of a website.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>The essence of the software product:</Highlight> The essence of the software product: Service, helps to generate the required number of NFT images.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Price:</Highlight> 5000 $C4 per 1000 images generated. Price might change if price of $C4 changes.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Required investment:</Highlight> 5,000,000 C4
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Payback period of the project:</Highlight> from 11 months. Project works in real life market conditions and time required for payback in $C4 might change in case of significant change of $C4 price.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Investor benefit:</Highlight> 70% from the project income during all of the existance of the project.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Market capacity:</Highlight> about 1,000 potential NFT collections. Based on statistics, each project needs from 5 to 15 tries of the generator before each mint to exclude all mistakes of the designers and make collection that satisfies all requirements.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Install time:</Highlight> up to 2 weeks after full amount of $c4 required is collected.
						</ProjectListItem>
						<ProjectListItem>
							<Highlight>Project demo:</Highlight> coming soon
						</ProjectListItem>
					</ProjectList>
				</ProjectTextContent>

				<ProjectTextContent>
					<ProjectImgBox className={'securevpn--table-1'} img={'./img/table-4.png'} width={650} height={217} />
				</ProjectTextContent>
				<ProjectBuy onClick={() => alert('Coming soon')} />
			</ProjectWrap>
		</Container>
	);
};
