import { Container } from '../../shared/components/Container';
import './styles.scss';

export const Footer = () => {
	return (
		<div className="footer">
			<Container className="footer__container">
				<div className="footer__text">© 2023 CodeWare All Rights Reserved</div>
				<div className="footer__text">Privacy Policy</div>
			</Container>
		</div>
	);
};
